<template>
  <div class="subject" :class="{ 'subject--is-expanded': detailIsExpanded, 'subject--expand-slot-area': slotIsExpanded }">
    <div class="subject__index d-flex align-items-center">
      <span class="subject__sequence">{{ customSequenceTitle }}</span>
      <i v-if="enablePreIcon && type" :class="subjectTypeIcon(type)" class="mdi mdi-18px ml-3"></i>
    </div>

    <div class="subject__title">
      <b-form-input :class="{ 'is-invalid': validate && titleValidState === false }" ref="titleInputRef" v-model="vModelForTitle" @input="value => $emit('update:title', value)" :disabled="isReadOnly" :state="validate ? titleValidState : null" :placeholder="validate && titleValidState === false ? validationMessage : ''"></b-form-input>
    </div>

    <div class="subject__sorting d-flex align-items-center">
      <i class="mdi mdi-24px mdi-drag-horizontal cursor-grab subject-layout-drag-handler"></i>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveUpIsDisabled || isReadOnly }" variant="secondary" @click="$emit('top')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-collapse-up"></span>
        移至最頂
      </b-button>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveUpIsDisabled || isReadOnly }" variant="secondary" @click="$emit('up')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-up"></span>
        上移
      </b-button>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveDownIsDisabled || isReadOnly }" variant="secondary" @click="$emit('down')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-down"></span>
        下移
      </b-button>
      <b-button class="ml-2 subject__button" :class="{ 'subject__button--disabled': moveDownIsDisabled || isReadOnly }" variant="secondary" @click="$emit('bottom')" :disabled="isReadOnly">
        <span class="mdi mdi-arrow-collapse-down"></span>
        移至最底
      </b-button>
    </div>

    <div class="subject__deletion d-flex align-items-center">
      <b-button class="subject__button" variant="danger" @click="confirmForDeletion" :disabled="isReadOnly">
        <span class="mdi mdi-delete"></span>
        移除
      </b-button>
    </div>

    <div class="subject__options" :class="{ 'd-none': !detailIsExpanded }">
      <b-input-group class="subject__advance-option-input" v-if="placeholderIsVisible">
        <b-input-group-prepend>
          <span class="mdi mdi-information-outline"></span>
        </b-input-group-prepend>
        <b-form-input ref="placeholderRef" v-model="vModelForPlaceholder" @input="value => $emit('update:placeholder', value)"></b-form-input>
      </b-input-group>
      <b-input-group class="subject__advance-option-input" v-if="memoIsVisible">
        <b-input-group-prepend>
          <span class="mdi mdi-comment-text-outline"></span>
        </b-input-group-prepend>
        <b-form-input ref="memoRef" v-model="vModelForMemo" @input="value => $emit('update:memo', value)"></b-form-input>
      </b-input-group>
      <b-input-group class="subject__advance-option-input" v-if="locationIsVisible">
        <b-input-group-prepend>
          <span class="mdi mdi-image-filter-center-focus-weak"></span>
        </b-input-group-prepend>
        <div class="subject__advance-option-content-wrapper">
          <span>分頁：</span>
          <b-form-select
            v-model="vModelForPage"
            :options="pageCandidates"
            @input="handlePageChanges"
            style="max-width: 120px"
          ></b-form-select>
          <span>，分區：</span>
          <b-form-select
            v-model="vModelForSection"
            :options="sectioncandidates"
            @input="handleSectionChanges"
            style="max-width: 120px"
          ></b-form-select>
        </div>
      </b-input-group>
      <template v-if="visibleConditionIsVisible">
        <b-input-group class="subject__advance-option-input" v-for="(conditionIndex) in displayConditions" :key="conditionIndex">
            <b-input-group-prepend>
              <span class="mdi mdi-lightbulb-on-outline"></span>
            </b-input-group-prepend>
            <div class="subject__advance-option-content-wrapper">
              <span>當</span>
              <b-form-select
                v-model="conditionList[conditionIndex - 1].field_id"
                :options="visibleConditionFieldCandidates"
                @input="val => handleVisibleConditionFieldChanges(conditionIndex - 1, val)"
                style="max-width: 160px"
              ></b-form-select>
              <span>為</span>
              <b-form-select
                v-model="conditionList[conditionIndex - 1].value"
                :options="visibleConditionValueCandidates"
                @input="val => handleVisibleConditionOptionChanges(conditionIndex - 1, val)"
                style="max-width: 160px"
              ></b-form-select>
              <!-- 增加減少 -->
              <b-button class="ml-2 p-1 d-inline-flex align-items-center" variant="inverse-success" @click="createCondition(conditionIndex - 1)">
                <span class="mdi mdi-18px mdi-playlist-plus"></span>
                增加
              </b-button>
              <b-button class="ml-2 p-1 d-inline-flex align-items-center" variant="inverse-danger" @click="removeCondition(conditionIndex - 1)" :disabled="isOnlyOneCondition()">
                <span class="mdi mdi-18px mdi-playlist-remove"></span>
                移除
              </b-button>
            </div>
          
        </b-input-group>
      </template>
      <div class="subject__toggle-wrapper">
        <b-button v-if="enableOptionList" class="subject__button" :variant="vModelForRequired ? 'primary' : 'secondary'" @click="vModelForRequired = !vModelForRequired; $emit('update:required', vModelForRequired)" :disabled="isReadOnly">
          <span class="mdi mdi-star-outline"></span>
          必填
        </b-button>
        <b-button v-if="hasPlaceholder" class="subject__button" :variant="placeholderIsVisible ? 'primary' : 'secondary'" @click="togglePlaceholderValue" :disabled="isReadOnly">
          <span class="mdi mdi-information-outline"></span>
          提示文字
        </b-button>
        <b-button v-if="enableOptionList" class="subject__button" :variant="memoIsVisible ? 'primary' : 'secondary'" @click="toggleMemoValue" :disabled="isReadOnly">
          <span class="mdi mdi-comment-text-outline"></span>
          填寫說明
        </b-button>
        <b-button v-if="locationIsEnabled && enableOptionList" class="subject__button" :variant="locationIsVisible ? 'primary' : 'secondary'" @click="toggleLocation" :disabled="isReadOnly">
          <span class="mdi mdi-image-filter-center-focus-weak"></span>
          顯示區域
        </b-button>
        <b-button v-if="enableVisibleCondition" class="subject__button" :variant="visibleConditionIsVisible ? 'primary' : 'secondary'" @click="toggleVisibleCondition" :disabled="isReadOnly" >
          <span class="mdi mdi-lightbulb-on-outline"></span>
          觸發顯示
        </b-button>
        <b-button v-if="formType === 'booking'" class="subject__button" :variant="vModelForDashboardOnly ? 'primary' : 'secondary'" @click="toggleDashboardOnly" :disabled="isReadOnly">
          <span class="mdi mdi-laptop"></span>
          僅限後台編輯
        </b-button>
        <b-button v-if="formType === 'collection'" class="subject__button" :variant="vModelForMainDisplay ? 'primary' : 'secondary'" @click="toggleMainDisplay" :disabled="isReadOnly">
          <span class="mdi mdi-label-outline"></span>
          下拉選單以這欄位當作顯示文字
        </b-button>
        <b-button v-if="formType === 'booking'" class="subject__button" :variant="vModelForListHidden ? 'primary' : 'secondary'" @click="toggleListHidden" :disabled="isReadOnly">
          <span class="mdi mdi-eye-off"></span>
          隱藏於列表欄位
        </b-button>
      </div>
    </div>

    <div class="subject__preview" :class="{ 'd-none': !detailIsExpanded || !enablePreview }">
      <span class="font-weight-bold">預覽：</span>
      <b-card class="rounded rounded-lg subject__preview-box">
        <slot name="preview"></slot>
      </b-card>
    </div>

    <div class="subject__slot" :class="{ 'd-none': !detailIsExpanded }">
      <slot></slot>
    </div>

    <div class="subject__expand">
      <b-button class="d-flex align-items-center justify-content-center w-100 border-0 bg-white" @click="toggleDetails">
        <i class="mdi mdi-24px mdi-chevron-down"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
import { subjectTypeIcon } from '@/utils/DynamicFormUtils';
import _ from "lodash";

export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    memo: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    dashboardOnly: {
      type: Boolean,
      default: false,
    },
    mainDisplay: {
      type: Boolean,
      default: false,
    },
    listHidden: {
      type: Boolean,
      default: false,
    },
    moveUpIsDisabled: {
      type: Boolean,
      default: false,
    },
    moveDownIsDisabled: {
      type: Boolean,
      default: false,
    },
    hasPlaceholder: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    formType: {
      type: String,
      default: 'survey',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    page: {
      type: String,
      required: false,
    },
    pageOptions: {
      type: Array,
      default: () => ([]),
    },
    section: {
      type: String,
      required: false,
    },
    sectionOptions: {
      type: Array,
      default: () => ([]),
    },
    visibleConditions: {
      type: Array,
      default: () => ([]),
    },
    fieldOptionsForVisibleConditions: {
      type: Array,
      default: () => ([]),
    },
    validate: {
      type: Boolean,
      default: false,
    },
    validationMessage: {
      type: String,
      default: '此欄位為必填',
    },
    enableOptionList: {
      type: Boolean,
      default: true,
    },
    enablePreview: {
      type: Boolean,
      default: true,
    },
    enablePreIcon: {
      type: Boolean,
      default: true,
    },
    enableVisibleCondition: {
      type: Boolean,
      default: false,
    },
    slotIsExpanded: {
      type: Boolean,
      default: false,
    },
    customSequenceTitle: {
      type: String,
      default(){
        return `第 ${this.index + 1} 題`
      },
    }
  },
  data() {
    return {
      detailIsExpanded: false,
      vModelForTitle: '',
      vModelForRequired: false,
      vModelForMemo: '',
      vModelForPlaceholder: '',
      memoIsVisible: false,
      placeholderIsVisible: false,
      vModelForDashboardOnly: false,
      vModelForMainDisplay: false,
      vModelForListHidden: false,
      titleInputRef: null,
      vModelForPage: null,
      vModelForSection: null,
      locationIsVisible: false,
      visibleConditionIsVisible: false,
      displayConditions: 1,
      conditionList: [],
    }
  },
  computed: {
    isReadOnly() {
      return this.readonly
    },
    locationIsEnabled() {
      return ['BookingPresetEdit'].includes(this.$route.name);
    },
    pageCandidates() {
      return [
        { text: '不設定分頁', value: null },
        ...this.pageOptions.map(option => ({ text: option.title, value: option._id })),
      ]
    },
    sectioncandidates() {
      return [
        { text: '不設定分區', value: null },
        ...this.sectionOptions
          .filter(option => this.vModelForPage ? option.page_id === this.vModelForPage : !option.page_id)
          .map(option => ({ text: option.title, value: option._id })),
      ]
    },
    visibleConditionFieldCandidates() {
      return [
        { text: '特定題目', value: null },
        ...this.fieldOptionsForVisibleConditions.filter(field => Object.keys(field).includes('options')).map(field => ({ text: field.title, value: field._id })),
      ]
    },
    visibleConditionValueCandidates() {
      return [
        { text: '特定選項', value: null },
        ..._.get(this.fieldOptionsForVisibleConditions.find(field => this.conditionList.map(cond => cond.field_id).includes(field._id)), 'options', []),
      ]
    },
    titleValidState(){
      const passStyle = null;
      const errorStyle = false;
      return this.vModelForTitle.trim() ?  passStyle : errorStyle;
    }
  },
  mounted() {
    this.vModelForTitle = this.title
    this.vModelForRequired = this.required
    this.vModelForMemo = this.memo
    this.vModelForPlaceholder = this.placeholder
    this.vModelForDashboardOnly = this.dashboardOnly
    this.vModelForMainDisplay = this.mainDisplay
    this.vModelForListHidden = this.listHidden

    this.memoIsVisible = !!this.vModelForMemo
    this.placeholderIsVisible = !!this.vModelForPlaceholder
    this.detailIsExpanded = this.expanded

    if (!!this.page || !!this.section) {
      this.locationIsVisible = true
      this.vModelForPage = this.page || null
      this.vModelForSection = this.section || null
    }

    if (this.visibleConditions.length > 0) {
      this.visibleConditionIsVisible = true
      this.conditionList = this.visibleConditions.map(condition => ({
        field_id: condition.field_id,
        value: condition.value,
      }))
      this.displayConditions = this.conditionList.length
    }
  },
  methods: {
    subjectTypeIcon,
    createCondition(index) {
      this.conditionList.splice(index + 1, 0, {
        field_id: null,
        value: null,
      });
      this.displayConditions = this.conditionList.length;
    },
    removeCondition(index) {
      this.conditionList.splice(index, 1);
      this.displayConditions = this.conditionList.length;
      this.$emit('update:visibleConditions', _.cloneDeep(this.conditionList))
    },
    isOnlyOneCondition() {
      return this.displayConditions === 1;
    },
    async confirmForDeletion() {
      const consented = await this.$swal({
        title: this.title ? `確認刪除【${this.title}】嗎？` : '確認刪除嗎？',
        // text: ,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonColor: "#3085d6",
        cancelButtonText: "不刪除",
        confirmButtonColor: "#d33",
        confirmButtonText: "確定刪除",
        reverseButtons: false,
      })

      if (!consented.value) {
        return
      }

      this.$emit('remove')
    },
    toggleMemoValue() {
      if (this.memoIsVisible) {
        this.vModelForMemo = ''
        this.$emit('update:memo', '')
      }

      this.memoIsVisible = !this.memoIsVisible

      this.$nextTick(() => {
        if (this.memoIsVisible) {
          this.$refs.memoRef.focus()
        }
      })
    },
    togglePlaceholderValue() {
      if (this.placeholderIsVisible) {
        this.vModelForPlaceholder = ''
        this.$emit('update:placeholder', '')
      }

      this.placeholderIsVisible = !this.placeholderIsVisible

      this.$nextTick(() => {
        if (this.placeholderIsVisible) {
          this.$refs.placeholderRef.focus()
        }
      })
    },
    toggleLocation() {
      this.locationIsVisible = !this.locationIsVisible

      if (this.locationIsVisible === false) {
        this.vModelForPage = null
        this.vModelForSection = null
        this.$emit('update:page', this.vModelForPage)
        this.$emit('update:section', this.vModelForSection)
      }
    },
    toggleVisibleCondition() {
      this.visibleConditionIsVisible = !this.visibleConditionIsVisible

      if (!this.visibleConditionIsVisible) {
        this.conditionList = []
        this.$emit('update:visibleConditions', [])
        this.displayConditions = 1
      } else {
        this.conditionList = [{
          field_id: null,
          value: null,
        }]
      }
    },
    toggleDetails() {
      this.detailIsExpanded = !this.detailIsExpanded
      this.$emit('update:expanded', this.detailIsExpanded)
    },
    toggleDashboardOnly() {
      this.vModelForDashboardOnly = !this.vModelForDashboardOnly;
      this.$emit('update:dashboardOnly', this.vModelForDashboardOnly);
    },
    toggleMainDisplay() {
      this.vModelForMainDisplay = !this.vModelForMainDisplay;
      this.$emit('update:mainDisplay', this.vModelForMainDisplay);
    },
    toggleListHidden() {
      this.vModelForListHidden = !this.vModelForListHidden;
      this.$emit('update:listHidden', this.vModelForListHidden);
    },
    handlePageChanges() {
      this.$emit('update:page', this.vModelForPage)
      this.vModelForSection = null
      this.$emit('update:section', this.vModelForSection)
    },
    handleSectionChanges() {
      this.$emit('update:section', this.vModelForSection)
    },
    handleVisibleConditionFieldChanges(conditionIndex, val) {
      const originalCondition = _.cloneDeep(this.conditionList);
      if(val){
        this.conditionList[conditionIndex] = {
          field_id: val,
          value: null,
        };
        this.$emit('update:visibleConditions', _.cloneDeep(this.conditionList))
      } else {
        originalCondition.splice(conditionIndex, 1);
        this.$emit('update:visibleConditions', originalCondition)
      }
    },
    handleVisibleConditionOptionChanges(conditionIndex, val) {
      this.conditionList[conditionIndex].value = val;
      this.$emit('update:visibleConditions', _.cloneDeep(this.conditionList))
    },
  }
}
</script>

<style lang="scss" scoped>
.subject {
  display: grid;
  grid-template-columns: 114px auto 350px 60px;
  grid-gap: 0 16px;
  grid-template-areas:
    "index  title   sorting deletion"
    ".      options preview preview"
    "slot   slot    preview preview"
    "expand expand  expand  expand";

  &--expand-slot-area {
    grid-template-areas:
      "index  title   sorting deletion"
      ".      options preview preview"
      "slot   slot    slot    slot"
      "expand expand  expand  expand";
  }

  $sections: index, title, sorting, deletion, options, preview, slot, expand;
  @each $sectionName in $sections {
    &__#{$sectionName} {
      grid-area: $sectionName;
    }
  }

  &--is-expanded {
    .subject__expand i {
      transform: rotate(180deg);
    }
  }
  .is-invalid::placeholder {
    color: #dc3545;
  }
  .subject__sequence {
    border-radius: 4px;
    padding: 4px 12px;
    background-color: #eeeeee;
    font-size: .875rem;
  }
  &__options {
    margin-top: 16px;
  }
  &__slot {
    margin-top: 16px;
  }
  &__preview {
    margin-top: 16px;
  }
  &__advance-option-input {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: #eee;

    .input-group-prepend {
      background-color: transparent;
    }

    span.mdi {
      margin-left: 12px;
    }

    input.form-control {
      border: none;
      background-color: transparent;
    }

    select.custom-select {
      margin: 0 12px;
      font-size: 0.75rem;
      font-weight: 300;
    }
  }

  &__advance-option-content-wrapper {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding: 8px 12px;

    span {
      font-size: 0.75rem;
      font-weight: 300;
      white-space: nowrap;
    }
  }

  &__button {
    padding: 4px 6px;

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }

  &__toggle-wrapper {
    margin: -2px -4px;

    > * {
      margin: 2px 4px;
    }
  }

  .subject__preview-box {
    background-color: #e7e7e7;
  }
}
</style>
